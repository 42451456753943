import * as React from 'react'
import Layout from '../components/layout'
import { Link } from "gatsby"
import imageError from '../assets/images/404-img.jpg'
// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="404 Not found">
      <div className='grid grid-cols-1 lg:grid-cols-12 gap-14'>
          <div className='lg:col-span-8 mb-4'>
            <h2 className='text-xl'>Sorry Page not found</h2>
            <p>We couldn't find what you were looking for.</p>
            <Link to="/" className='my-3 inline-block text-md uppercase bg-slate-800 text-white py-3 px-5'>Go back home</Link>
          </div>
          {/* <div className='lg:col-span-4 mb-4'>
            <Link to="/contact">        
              <img src={imageError} className='rounded' alt='404 error image' title='404 Sorry the Page was not found' />
            </Link>      
          </div> */}
      </div>
    </Layout>
  )
}

export default NotFoundPage